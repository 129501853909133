<style lang="less" scoped>
@import "./UserCentre.less";
</style>

<template>
  <div style="width: 100%">
    <div>
      <Row :gutter="16">
        <Col
          :lg="{ span: 8 }"
          :md="{ span: 24 }"
          :xs="{ span: 24 }"
          span="8"
          class="info-card"
        >
          <Card>
            <h2 slot="title">个人信息&nbsp;</h2>
            <a style="font-size: 15px" @click="handleEdit()" slot="extra">
              修改
            </a>

            <div class="info-details" style="">
              <Avatar
                style="background-color: #2d8cf0"
                size="90"
                icon="md-person"
              />

              <h3 id="title" style="padding: 5px 0 0 0">
                {{ this.userInfo.display_name }}
              </h3>

              <h4 style="font-weight: normal">
                {{ this.userInfo.campus }} - {{ this.userInfo.faculty }}
              </h4>

              <Divider size="small" />

              <h3>
                <span>邮件</span>
                <span class="text-right">
                  {{ this.userInfo.email }}
                </span>
              </h3>

              <Divider size="small" />

              <h3>
                <span>电话</span>
                <span class="text-right">
                  {{ this.userInfo.telephone }}
                </span>
              </h3>

              <Divider size="small" />

              <h3>
                <span>微信</span>
                <span class="text-right">
                  {{ this.userInfo.wx }}
                </span>
              </h3>
              <!-- <br /> -->
            </div>
            <!-- <div style="width: 30%;float: right; padding: 20px 0;"> -->

            <!-- </div> -->
          </Card>
        </Col>
        <Col :lg="{ span: 16 }" :md="{ span: 24 }" :xs="{ span: 24 }" span="8">
          <Card>
            <Menu
              mode="horizontal"
              theme="light"
              active-name="enrolledUnits"
              @on-select="onSubmenuSelect"
            >
              <MenuItem name="enrolledUnits">
                <Icon type="ios-paper" />
                我的选课
              </MenuItem>
              <!-- <MenuItem name="materials">
                <Icon type="ios-paper" />
                资料中心
              </MenuItem> -->
            </Menu>
            <br />
            <div>
              <router-view></router-view>
            </div>
          </Card>
        </Col>
        <!-- <Col :lg="{ span: 8 }" :md="{ span: 12 }" :xs="{ span: 24 }" span="8">
                  <Card style="height: 300px;"
                    ><h2 slot="title">报名新课</h2>
                    <div>
                      <img
                        src="../../assets/monash.jpeg"
                        :style="{ cursor: 'pointer' }"
                        height="100%"
                        width="100%"
                      /></div></Card
                ></Col>
                <Col :lg="{ span: 8 }" :md="{ span: 12 }" :xs="{ span: 24 }" span="8">
                  <Card style="height: 300px;"
                    ><h2 slot="title">资料下载</h2>
                    <div>
                      <img
                        src="../../assets/monash.jpeg"
                        :style="{ cursor: 'pointer' }"
                        height="100%"
                        width="100%"
                      /></div></Card
                ></Col>-->
      </Row>
    </div>
  </div>
</template>
<script>
// import EnrolledUnits from "../enrolledUnits/EnrolledUnits";
// import Materials from "../materials/Materials";
export default {
  name: "userCentre",
  //   components: {
  //     EnrolledUnits,
  //     Materials,
  //   },
  data() {
    return {
      //   enrolled_units_visible: true,
      //   materials_visible: false,
      enrolledUnits: [],
      userInfo: "",
      courseStreaming: {
        upcoming_title: "NaN",
        mid: "0",
        password: "NaN",
        zoomLink: "NaN",
        detail_text: "NaN",
        pre_detail_text: "NaN",
        theme1: "light"
      }
    };
  },
  methods: {
    onCardSelect: function (name) {
      this.$router.push({
        path: "/unitinfo",
        query: {
          id: name
        }
      });
    },
    handleEdit() {
      this.$router.push({
        path: "/edituser",
        query: {
          id: this.userInfo.username
        }
      });
    },
    getVideoLink(url) {
      const pattern =
        /https:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/gu;
      // console.log(pattern.test(url));
      url = url.match(pattern);
      //   console.log(url);
      return url[0];
    },
    // onMenuSelect: function(name) {
    //   this.routeActiveName = name;
    //   //   console.log(name);
    //   if (name === "enrolled_units_visible") {
    //     this.enrolled_units_visible = true;
    //     this.materials_visible = false;
    //   }
    //   if (name === "enrolled_units_visible") {
    //     this.enrolled_units_visible = false;
    //     this.materials_visible = true;
    //   }
    // },
    onSubmenuSelect: function (name) {
      this.$router.push(`/usercentre/${name}`);
    }
  },
  mounted: function () {
    const username = sessionStorage.getItem("username");
    this.axios
      .get(`api/sessions`)
      .then(resp => {
        this.userInfo = resp.data;
      })
      .catch(error => {
        this.$Message.error("登陆信息错误，请尝试重新登陆", error);
      });
    this.axios
      .get(`api/student/${username}/courses`)
      .then(resp => {
        const courseCodeList = resp.data
          .filter(item => item.paid)
          .map(paidItem => paidItem.course_code);
        //   console.log(courseCodeList);
        return courseCodeList;
      })
      .then(async courseCodeList => {
        const courseList = [];
        for (const courseCode of courseCodeList) {
          const response = await this.axios.get(`api/course/${courseCode}`);
          const data = await response.data;
          // console.log(data);
          // const resp2 = await this.axios.get(
          //   `api/course/${courseCode}/details/course_streaming`
          // );
          // data.detail_text = resp2.data.detail_text;
          // data.zoomLink = this.getVideoLink(resp2.data.detail_text);
          // console.log(data);
          courseList.push(data);
          // console.log(courseList);
        }

        this.enrolledUnits = courseList;
        //   console.log(this.enrolledUnits);
      });
    //   this.$router.push(`/usercentre/enrolledunits`);
  }
};
</script>
